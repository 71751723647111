import React from 'react'
import { NavLink } from "react-router-dom";
import ico_pdf from "../../images/ico_pdf.png";
function ContabilidadGubernamental_3() {
    return (
        <>
            <section id="grl_contenido">
                <div style={{ marginTop: "-100px" }}>
                <div style={{ clear: "both" }}></div>
                <div className="con1">
                    <div className="table-responsive">
                    <section id="ags-secction-contenido_general">
                        <div id="ContentPlaceHolder1_encabezado">
                        <div className='encabezado'>
                            <NavLink to="/contabilidadGubernamental">
                            <h2 to="/contabilidadGubernamental">Contabilidad Gubernamental
                            <span className='pib'>|</span>
                            </h2>
                            </NavLink>
                        </div>
                        </div>
                        <div id="ContentPlaceHolder1_linksurl">
                        <div className='linksurl'>
                            <NavLink to="/">
                            <img src="/images/boletines/linkhome.png" style={{ width: "16px", height: "14px" }} />
                            </NavLink>
                        </div>
                        </div>
                        <hr />
                        <div id="ContentPlaceHolder1_contenido_bd">
                        <div className="table-responsive" id="contenedor_tablas">  
                            
                            <table className="tabTrans table table-hover" style={{ margin: "0px auto", width: "100%" }} cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                <td colSpan="3" style={{ height: "50px", border: "1px solid #DCDCDC", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                    <span style={{ color: "#2d62bf" }}>3er Trimestre 2024</span>
                                </td>
                                </tr>
                                <tr>
                                <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                                    Formatos:
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    &nbsp; Documento:
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/EGRESOS-CLASIFICACION-FUNCIONAL.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/EGRESOS-CLASIFICACION-FUNCIONAL.pdf">
                                    &nbsp; Egresos por Clasificacion Funcional 
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/EGRESOS-POR-CLASIFICACION-ADMINISTRATIVA.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/EGRESOS-POR-CLASIFICACION-ADMINISTRATIVA.pdf">
                                    &nbsp; Egresos por Clasificacion Administrativa
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/EGRESOS-POR-CLASIFICACION-ECONOMICA-POR-TIPO-DE-GASTO.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/EGRESOS-POR-CLASIFICACION-ECONOMICA-POR-TIPO-DE-GASTO.pdf">
                                    &nbsp; Egresos por Clasificacion Economica por Tipo de Gasto
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/EGRESOS-POR-CLASIFICACION-POR-OBJETO-DE-GASTO.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/EGRESOS-POR-CLASIFICACION-POR-OBJETO-DE-GASTO.pdf">
                                    &nbsp; Egresos por Clasificacion por Objeto de Gasto
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ENDEUDAMIENTO-NETO.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ENDEUDAMIENTO-NETO.pdf">
                                    &nbsp; Endeudamiento Neto
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-ANALITICO-DE-INGRESOS.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-ANALITICO-DE-INGRESOS.pdf">
                                    &nbsp; Estado Analitico de Ingresos
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-ANALITICO-DE-LA-DEUDA-Y-OTRO-PASIVOS.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-ANALITICO-DE-LA-DEUDA-Y-OTRO-PASIVOS.pdf">
                                    &nbsp; Estado Analitico de la Deuda y Otros Pasivos
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-ANALITICO-DEL-ACTIVO.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-ANALITICO-DEL-ACTIVO.pdf">
                                    &nbsp; Estado Analitico del Activo
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-DE-ACTIVIDADES.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-DE-ACTIVIDADES.pdf">
                                    &nbsp; Estado de Actividades
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-DE-CAMBIOS-EN-LA-SITUACION-FINANCIERA.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-DE-CAMBIOS-EN-LA-SITUACION-FINANCIERA.pdf">
                                    &nbsp; Estado de Cambios en la Situacion Financiera
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-DE-FLUJOS-DE-EFECTIVO.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-DE-FLUJOS-DE-EFECTIVO.pdf">
                                    &nbsp; Estado de Flujos de Efectivo
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-DE-SITUACION-FINANCIERA.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-DE-SITUACION-FINANCIERA.pdf">
                                    &nbsp; Estado de Situacion Financiera
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-DE-VARIACION-EN-LA-HACIENDA-PUBLICA.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/ESTADO-DE-VARIACION-EN-LA-HACIENDA-PUBLICA.pdf">
                                    &nbsp; Estado de Variacion en la Hacienda Publica
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/FLUJO-DE-FONDOS.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/FLUJO-DE-FONDOS.pdf">
                                    &nbsp; Flujo de Fondos
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/GASTO-POR-CATEGORIA-PROGRAMATICA.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/GASTO-POR-CATEGORIA-PROGRAMATICA.pdf">
                                    &nbsp; Gasto por Categoria Programatica
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/INDICADORES-DE-RESULTADOS.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/INDICADORES-DE-RESULTADOS.pdf">
                                    &nbsp; Indicadores de Resultados
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/INFORME-SOBRE-PASIVOS-CONTINGENTES.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/INFORME-SOBRE-PASIVOS-CONTINGENTES.pdf">
                                    &nbsp; Informe sobre Pasivos Contingentes 
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/INTERESES-DE-LA-DEUDA.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/INTERESES-DE-LA-DEUDA.pdf">
                                    &nbsp; Intereses de la Deuda 
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/NOTAS-ESTADOS-FINANCIEROS.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/NOTAS-ESTADOS-FINANCIEROS.pdf">
                                    &nbsp; Notas a los Estados Financieros 
                                    </a>
                                </td>
                                </tr>
                                <tr>
                                <td style={{ height: "50px", textAlign:"center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/PROGRAMAS-Y-PROYECTOS-DE-INVERSION.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/PROGRAMAS-Y-PROYECTOS-DE-INVERSION.pdf">
                                    &nbsp; Programas y Proyectos de Inversion
                                    </a>
                                </td>
                                </tr>
                                <tr>
                                <td style={{ height: "50px", textAlign:"center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/AYUDAS-Y-SUBSIDIOS.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/AYUDAS-Y-SUBSIDIOS.pdf">
                                    &nbsp; Ayudas y Subsidios
                                    </a>
                                </td>
                                </tr>


                            </tbody>
                            </table>
                            
                            <br /><br />

                        </div>

                        
                        
                        </div>

                    </section>
                    </div>
                </div>

                </div>
            </section>
        </>
    )
}

export default ContabilidadGubernamental_3