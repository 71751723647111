import "./css/general.css";
import "./css/campslider.css";
import "./css/fullPrincipal.css";
import "./css/fullpage.css";
import "./css/carouselBoletines.css";
import "./css/mixSlide.css";
import "./css/internasAnterior.css";
import "./css/boletines.css";
import Index from './pages/Index';
import { Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import Ccapama from "./pages/Ccapama";
import Boletines from "./pages/Boletines";
import TramitesYServicios from "./pages/TramitesYServicios";
import Licitaciones from "./pages/Licitaciones";
import Contacto from "./pages/Contacto";
import Transparencia from "./pages/Transparencia";
import Articulos from "./pages/Articulos";
import DifusionDeAcciones from "./pages/DifusionDeAcciones";
import RecursosFederales from "./pages/RecursosFederales";
import SRFT from "./pages/SRFT";
import ContabilidadGubernamental from "./pages/ContabilidadGubernamental";
import ContabilidadGubernamental_1 from "./pages/ContabilidadGubernamental/ContabilidadGubernamental_1";
import ContabilidadGubernamental_2 from "./pages/ContabilidadGubernamental/ContabilidadGubernamental_2";
import ContabilidadGubernamental_3 from "./pages/ContabilidadGubernamental/ContabilidadGubernamental_3";
function App() {
  return (
    <>
      <div className="">
      <Header />
      </div>

      <section >
        <div className="">
          <div >

            <Routes>
              <Route path='/' element={<Index />} />
              <Route path='/ccapama' element={<Ccapama />} />
              <Route path='/boletines/:noBoletin' element={<Boletines />} />
              <Route path='/tramites-y-servicios' element={<TramitesYServicios />} />
              <Route path='/licitaciones' element={<Licitaciones />} />
              <Route path='/contacto' element={<Contacto />} />
              {/*}
              <Route path='/transparencia' element={<Transparencia />} />
              <Route path='/transparencia/articulo/:id' element={<Articulos />} />
              */}
              <Route path='/difusionDeAcciones' element={<DifusionDeAcciones />} />
              <Route path='/recursosFederales' element={<RecursosFederales />} />
              <Route path='/srft' element={<SRFT />} />
              <Route path='/contabilidadGubernamental' element={<ContabilidadGubernamental />} />
              <Route path='/contabilidadGubernamental/primertrimestre' element={<ContabilidadGubernamental_1 />} />
              <Route path='/contabilidadGubernamental/segundotrimestre' element={<ContabilidadGubernamental_2 />} />
              <Route path='/contabilidadGubernamental/tercertrimestre' element={<ContabilidadGubernamental_3 />} />
            </Routes>

          </div>
        </div>
      </section>

      <Footer />

    </>
  );
}

export default App;
